<template>
<div>
  <div class="bg-white"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <div class="bigTit">房主信息</div>
      <div class="formBox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-row class="paddingleft">
            <el-col :span="8">
              <el-form-item label="房主姓氏：" prop="lastname">
                <el-input v-model="ruleForm.lastname" placeholder="输入主人姓"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="房主名：" prop="name">
                <el-input v-model="ruleForm.name" placeholder="输入主人名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio label="男"></el-radio>
                  <el-radio label="女"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft">
            <el-col :span="8">
              <el-form-item label="出生日期：" prop="date">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择出生日期" v-model="ruleForm.date"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="在美状态：" prop="type">
                <el-select v-model="ruleForm.type" placeholder="选择在美状态">
                  <el-option label="美国公民" value="美国公民"></el-option>
                  <el-option label="永久居民" value="永久居民"></el-option>
                  <el-option label="合法签证" value="合法签证"></el-option>
                  <el-option label="加拿大公民" value="加拿大公民"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="族裔：" prop="zhongzu">
                <el-select v-model="ruleForm.zhongzu" placeholder="选择族裔">
                  <el-option label="中国" value="中国"></el-option>
                  <el-option label="中国+美国" value="中国+美国"></el-option>
                  <el-option label="韩国" value="韩国"></el-option>
                  <el-option label="韩国+美国" value="韩国+美国"></el-option>
                  <el-option label="白人" value="白人"></el-option>
                  <el-option label="西班牙/拉丁裔" value="西班牙/拉丁裔"></el-option>
                  <el-option label="非裔" value="非裔"></el-option>
                  <el-option label="其他亚洲/太平洋岛民" value="其他亚洲/太平洋岛民"></el-option>
                  <el-option label="亚裔" value="亚裔"></el-option>
                  <el-option label="未知" value="未知"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft">
            <el-col :span="8">
              <el-form-item label="婚姻状况：" prop="marriage">
                <el-select v-model="ruleForm.marriage" placeholder="选择婚姻状况">
                  <el-option label="单身" value="单身"></el-option>
                  <el-option label="已婚" value="已婚"></el-option>
                  <el-option label="离异" value="离异"></el-option>
                  <el-option label="分居" value="分居"></el-option>
                  <el-option label="丧偶" value="丧偶"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item label="居住年限：" prop="yearlength">
                <el-input v-model="ruleForm.yearlength" placeholder="输入在美居住年限"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft" style="padding-bottom:20px">
            <el-col :span="24">
                <el-form-item label="住家寄语：">
                    <el-input type="textarea" v-model="ruleForm.jydesc" :autosize="{minRows:4}" placeholder="输入住家寄语"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="rowwhitetopbor">
            <el-col :span="8">
              <el-form-item label="电话号码：" prop="tel">
                <el-input v-model="ruleForm.tel" placeholder="输入电话号码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱：" prop="email">
                <el-input v-model="ruleForm.email" placeholder="输入邮箱"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所在城市：" prop="state">
                <el-autocomplete
                  v-model="ruleForm.state"
                  :fetch-suggestions="querySearchAsync"
                  value-key="csyw"
                  :debounce="500"
                  placeholder="输入所在城市"
                  :popper-append-to-body="false"
                  @select="handleSelect"
                  @input="inputCityChange"
                >
                <template slot-scope="{item}">
                  <div class="zw">{{item.csyw}}</div>
                  <span class="yw">{{item.name}}</span>
                </template>
                </el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所在州：" prop="zhou">
                <el-autocomplete
                  v-model="ruleForm.zhou"
                  :fetch-suggestions="querySearchAsyncZhou"
                  value-key="csyw"
                  :debounce="500"
                  placeholder="输入所在州"
                  :popper-append-to-body="false"
                  @select="handleSelectZhou"
                  @input="inputZhouChange"
                >
                <template slot-scope="{item}">
                  <div class="zw">{{item.ywmc}}</div>
                  <span class="yw">{{item.name}}</span>
                </template>
                </el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="rowwhitebombor">
            <el-col :span="24">
                <el-form-item label="详细地址：" prop="address">
                    <el-input type="textarea" v-model="ruleForm.address" :autosize="{minRows:4}" placeholder="输入详细地址"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft" style="padding:45px 0 0 45px">
            <el-col :span="8">
                <el-form-item label="学历：" prop="education">
                    <el-select v-model="ruleForm.education" placeholder="选择学历">
                        <el-option label="高中" value="高中"></el-option>
                        <el-option label="专科" value="专科"></el-option>
                        <el-option label="本科" value="本科"></el-option>
                        <el-option label="硕士" value="硕士"></el-option>
                        <el-option label="博士" value="博士"></el-option>
                    </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="毕业院校：" prop="schoolname">
                    <el-input v-model="ruleForm.schoolname" placeholder="输入毕业院校"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="宗教信仰：" prop="religious">
                  <el-select v-model="ruleForm.religious" placeholder="选择宗教信仰">
                    <el-option label="佛教" value="佛教"></el-option>
                    <el-option label="印度教" value="印度教"></el-option>
                    <el-option label="天主教" value="天主教"></el-option>
                    <el-option label="基督教" value="基督教"></el-option>
                    <el-option label="犹太教" value="犹太教"></el-option>
                    <el-option label="伊斯兰教" value="伊斯兰教"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                    <el-option label="无宗教" value="无宗教"></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft">
            <el-col :span="8">
                <el-form-item label="目前职业：" prop="occupation">
                    <el-input v-model="ruleForm.occupation" placeholder="输入目前职业/从事领域"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="英文水平：" prop="englishlevel">
                    <el-select v-model="ruleForm.englishlevel" placeholder="选择英文水平">
                        <el-option label="母语" value="母语"></el-option>
                        <el-option label="精通" value="精通"></el-option>
                        <el-option label="良好" value="良好"></el-option>
                        <el-option label="初学者" value="初学者"></el-option>
                    </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft" style="padding-bottom:20px">
            <el-col :span="8">
                <el-form-item label="家庭第一语言：" prop="language1">
                  <el-select v-model="ruleForm.language1" placeholder="选择家庭第一语言">
                    <el-option label="中文" value="中文"></el-option>
                    <el-option label="德语" value="德语"></el-option>
                    <el-option label="英语" value="英语"></el-option>
                    <el-option label="日语" value="日语"></el-option>
                    <el-option label="法语" value="法语"></el-option>
                    <el-option label="俄语" value="俄语"></el-option>
                    <el-option label="韩语" value="韩语"></el-option>
                    <el-option label="意大利语" value="意大利语"></el-option>
                    <el-option label="葡萄牙语" value="葡萄牙语"></el-option>
                    <el-option label="西班牙语" value="西班牙语"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                    <el-option label="无" value="无"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="家庭第二语言：" prop="language2">
                  <el-select v-model="ruleForm.language2" placeholder="选择家庭第二语言">
                    <el-option label="中文" value="中文"></el-option>
                    <el-option label="德语" value="德语"></el-option>
                    <el-option label="英语" value="英语"></el-option>
                    <el-option label="日语" value="日语"></el-option>
                    <el-option label="法语" value="法语"></el-option>
                    <el-option label="俄语" value="俄语"></el-option>
                    <el-option label="韩语" value="韩语"></el-option>
                    <el-option label="意大利语" value="意大利语"></el-option>
                    <el-option label="葡萄牙语" value="葡萄牙语"></el-option>
                    <el-option label="西班牙语" value="西班牙语"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                    <el-option label="无" value="无"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="btnBox">
        <el-button round type="primary" @click="submitForm('ruleForm')">保存修改</el-button>
    </div>
</div>
</template>

<script>
import { addAndEdit,getHouseDetail,getCity,getZhou } from '../../../api/form'
export default {
  data() {
    return {
        loading: false,
        ruleForm: {
            lastname: '', // 姓
            name: '', // 名
            sex: '', // 性别
            date: '', // 出生日期
            type: '', // 在美状态
            zhongzu: '', // 族裔
            marriage: '', // 婚姻状况
            yearlength: '', // 在美居住年限
            jydesc: '', // 住家寄语
            tel: '', // 电话号码
            email: '', // 电子邮箱
            state: '', // 所在城市
            zhou: '', // 所在州
            address: '', // 详细地址
            education: '', // 学历
            schoolname: '', // 毕业院校
            religious: '', // 宗教信仰
            occupation: '', // 职业/领域
            englishlevel: '', // 英语水平
            language1: '', // 家庭第二语言
            language2: '', // 家庭第一语言
        },
        restaurants: [],
        timeout:  null,
        rules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        },
        cityname: "",
    }
  },
//生命周期 - 创建完成（访问当前this实例）
  created() {
    
  },
//生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // 回显
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    inputCityChange(e){
      if(e == ''){
        this.ruleForm.stateid = ''
      }
    },
    inputZhouChange(e){
      if(e == ''){
        this.ruleForm.zhouid = ''
      }
    },
    // 回显
    getDetail(id){
      this.loading = true
      getHouseDetail({id: id}).then((res)=>{
        if(res.code == 200){
          if(res.data != null){
            if(res.data.chengshi != null){
              if(res.data.chengshi.csyw != null){
                this.ruleForm.state = res.data.chengshi.csyw // 所在城市 英文
              }else if(res.data.chengshi.name != null){
                this.ruleForm.state = res.data.chengshi.name // 所在城市 中文
              }else{
                this.ruleForm.state = ''
              }
              this.ruleForm.stateid = res.data.chengshi.id// 城市id

              if(res.data.sheng != null){
                if(res.data.sheng.ywmc){
                  this.ruleForm.zhou = res.data.sheng.ywmc // 所在州 英文
                }else if(res.data.sheng.name){
                  this.ruleForm.zhou = res.data.sheng.name // 所在州 中文
                }else{
                  this.ruleForm.zhou = ''
                }
              }
              this.ruleForm.zhouid = res.data.chengshi.id// 州id
            }
            this.ruleForm.lastname = res.data.js_lastname // 姓
            if(!isNaN(res.data.name)){
              this.ruleForm.name = ''
            }else{
              this.ruleForm.name = res.data.name // 名
            }
            this.ruleForm.sex = res.data.js_sex // 性别
            this.ruleForm.date = res.data.js_birthday // 出生日期
            this.ruleForm.type = res.data.js_jlzt // 在美状态
            this.ruleForm.zhongzu = res.data.js_race // 族裔
            this.ruleForm.marriage = res.data.js_marriage // 婚姻状况
            this.ruleForm.yearlength = res.data.js_lifespan // 在美居住年限
            this.ruleForm.jydesc = res.data.jsjt_zrjy // 住家寄语
            this.ruleForm.tel = res.data.js_phone_one // 电话号码
            this.ruleForm.email = res.data.js_mail // 电子邮箱
            this.ruleForm.address = res.data.js_site, // 详细地址
            this.ruleForm.education = res.data.js_education // 学历
            this.ruleForm.schoolname = res.data.js_university // 毕业院校
            this.ruleForm.religious = res.data.js_beliefs // 宗教信仰
            this.ruleForm.occupation = res.data.js_occupation // 职业/领域
            this.ruleForm.englishlevel = res.data.js_english_level // 英语水平
            this.ruleForm.language1 = res.data.js_firstlanguage // 家庭第一语言
            this.ruleForm.language2 = res.data.js_second_language // 家庭第二语言
            this.loading = false
          }
        }
      });
    },
      querySearchAsync(queryString, cb) {
        getCity({
          cityname:queryString
        }).then((res)=>{
          if(res.code == 200){
            var restaurants = res.data;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(restaurants);
            }, 500);
          }
        });
      },
      querySearchAsyncZhou(queryString, cb) {
        getZhou({
          province:queryString
        }).then((res)=>{
          if(res.code == 200){
            var restaurants = res.data;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(restaurants);
            }, 500);
          }
        });
      },
      handleSelect(item) {
        if(item.csyw){
          this.ruleForm.state = item.csyw;
        }else{
          this.ruleForm.state = item.name;
        }
        this.ruleForm.stateid = item.id
      },
      handleSelectZhou(item) {
        if(item.ywmc){
          this.ruleForm.zhou = item.ywmc;
        }else{
          this.ruleForm.zhou = item.name;
        }
        this.ruleForm.zhouid = item.id
      },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 添加、编辑
          addAndEdit({
            id:this.$route.query.id,
            js_lastname:this.ruleForm.lastname,
            name:this.ruleForm.name,
            js_sex:this.ruleForm.sex,
            js_birthday:this.ruleForm.date,
            js_jlzt:this.ruleForm.type,
            js_race:this.ruleForm.zhongzu,
            js_marriage:this.ruleForm.marriage,
            js_lifespan:this.ruleForm.yearlength,
            jsjt_zrjy:this.ruleForm.jydesc,
            js_phone_one:this.ruleForm.tel,
            js_mail:this.ruleForm.email,
            js_city:this.ruleForm.stateid,
            js_province:this.ruleForm.zhouid,
            js_site:this.ruleForm.address,
            js_education:this.ruleForm.education,
            js_university:this.ruleForm.schoolname,
            js_beliefs:this.ruleForm.religious,
            js_occupation:this.ruleForm.occupation,
            js_english_level:this.ruleForm.englishlevel,
            js_firstlanguage:this.ruleForm.language1,
            js_second_language:this.ruleForm.language2
          }).then((res)=>{
            if(res.code == 200){
              if(res.data != ''){
                let path = this.$router.history.current.path;
                this.$router.push({ path, query: {id: res.data} });
              }
              this.$message({
                message: '保存成功，继续下一步',
                type: 'success'
              });
              // 修改步骤条状态
              this.$emit('stepactive', 1)
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
/deep/ .el-autocomplete-suggestion__wrap {
    li {
      line-height: normal;
      padding: 7px;
      .zw {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .yw {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .zw {
        color: #ddd;
      }
    }
  }
// form
.bg-white{
  background: #ffffff;
}
.bigTit{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E1E1E;
    border-bottom: 1px solid #EFEFEF;
    margin: 0 0 0 42px;
    padding:35px 0 22px 0;
}
.formBox{
    padding-top:35px;
}
.demo-ruleForm{
    .paddingleft{
        padding-left: 45px;
    }
    .padding45{
        padding: 45px 0 20px 45px;
    }
    .padding45-2{
        padding: 45px 0 0 45px;
    }
    .rowwhitetopbor{
        padding-left: 45px;
        border-top: 20px solid #FAFAFA;
        padding-top:45px;
    }
    .rowwhitebombor{
        padding-left: 45px;
        border-bottom: 20px solid #FAFAFA;
        padding-bottom: 20px;
    }
}
::v-deep .el-input{
  width: 240px;
}
::v-deep .el-textarea__inner{
    background: #F8F8F8;
    width: 98%;
}
.btnBox{
    text-align: center;
    padding: 50px 0 40px 0;
}
// ::v-deep .el-form-item__label{
//     width: 120px !important;
// }
// ::v-deep .el-form-item__error{
//     left:20px;
// }
</style>