<template>
  <div class="user-form">
    <el-dialog 
      @close="handleClose" 
      :visible="visible" 
      :title=" isEditDialog ? '编辑成员信息' : '新增成员' " 
      :close-on-click-modal="false" 
      width="32%"
    >
      <el-form ref="form" :model="formData" :rules="rules" :inline="false" label-width="120px">
        <el-form-item label="姓名：" prop="name">
          <el-input type="text" v-model="formData.name"  placeholder="输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="js_cy_sex">
            <el-radio-group v-model="formData.js_cy_sex">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期：" prop="js_birth2">
            <el-date-picker type="year" value-format="yyyy" placeholder="选择出生日期" v-model="formData.js_birth2"></el-date-picker>
        </el-form-item>
        <el-form-item label="关系：" prop="js_applicant">
            <el-select v-model="formData.js_applicant" placeholder="选择关系">
                <el-option label="父母" value="父母"></el-option>
                <el-option label="亲戚" value="亲戚"></el-option>
                <el-option label="配偶" value="配偶"></el-option>
                <el-option label="租客" value="租客"></el-option>
                <el-option label="子女" value="子女"></el-option>
                <el-option label="国际学生" value="国际学生"></el-option>
                <el-option label="其他" value="其他"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="在美状态：" prop="jtcy_zmjlzt">
            <el-select v-model="formData.jtcy_zmjlzt" placeholder="选择在美状态">
                <el-option label="美国公民" value="美国公民"></el-option>
                <el-option label="永久居民" value="永久居民"></el-option>
                <el-option label="合法签证" value="合法签证"></el-option>
                <el-option label="加拿大公民" value="加拿大公民"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="族裔：" prop="jtcy_zz">
            <el-select v-model="formData.jtcy_zz" placeholder="选择族裔">
                <el-option label="中国" value="中国"></el-option>
                <el-option label="中国+美国" value="中国+美国"></el-option>
                <el-option label="韩国" value="韩国"></el-option>
                <el-option label="韩国+美国" value="韩国+美国"></el-option>
                <el-option label="白人" value="白人"></el-option>
                <el-option label="西班牙/拉丁裔" value="西班牙/拉丁裔"></el-option>
                <el-option label="非裔" value="非裔"></el-option>
                <el-option label="其他亚洲/太平洋岛民" value="其他亚洲/太平洋岛民"></el-option>
                <el-option label="亚裔" value="亚裔"></el-option>
                <el-option label="未知" value="未知"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="学历：" prop="js_education2">
            <el-select v-model="formData.js_education2" placeholder="选择学历">
                <el-option label="高中" value="高中"></el-option>
                <el-option label="专科" value="专科"></el-option>
                <el-option label="本科" value="本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="博士" value="博士"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="职业：" prop="js_professional2">
          <el-input type="text" v-model="formData.js_professional2" placeholder="输入目前职业"></el-input>
        </el-form-item>
        <el-form-item label="宗教信仰：" prop="js_cy_beliefs">
            <el-select v-model="formData.js_cy_beliefs" placeholder="选择宗教">
              <el-option label="佛教" value="佛教"></el-option>
              <el-option label="印度教" value="印度教"></el-option>
              <el-option label="天主教" value="天主教"></el-option>
              <el-option label="基督教" value="基督教"></el-option>
              <el-option label="犹太教" value="犹太教"></el-option>
              <el-option label="伊斯兰教" value="伊斯兰教"></el-option>
              <el-option label="其他" value="其他"></el-option>
              <el-option label="无宗教" value="无宗教"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="在该地址居住：" prop="jtcy_sfzxdzjz">
            <el-select v-model="formData.jtcy_sfzxdzjz" placeholder="选择是否在该地址居住">
              <el-option label="是" value="true"></el-option>
              <el-option label="否" value="false"></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="handleCancle">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">提 交</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { addAndEditFamily } from '../../../api/form'
export default {
  props: {
    isEditDialog: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => {
        return {
            id:'', // id
            jtcy:'', // jtcy
            name: '', // 名
            js_cy_sex: '', // 性别
            js_birth2: '', // 出生日期
            js_applicant: '', // 关系
            jtcy_zmjlzt: '', // 在美状态
            jtcy_zz: '', // 族裔
            js_education2: '', // 学历
            js_professional2: '', // 职业
            js_cy_beliefs: '', // 宗教
            jtcy_sfzxdzjz:'', // 是否在该地址居住
        }
      }
    }
  },
  data() {
    return {
      rules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          js_applicant: [{ required: true, message: '请选择关系', trigger: 'blur' }],
        },
    }
  },
  computed: {
    visible() {
      return this.dialogVisible
    },
    formData() {
      return this.initialFormData
    }
  },
  
  methods:{
    handleClose() {
      this.$emit('close-dialog')
    },
    handleCancle() {
      this.$emit('close-dialog')
    },
    handleSubmit() {
      //提交表单
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.isEditDialog) {
            //编辑
            let data = this.formData
            addAndEditFamily(data).then((res) => {
                if(res.code == 200){
                    this.loading = false
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                    this.updateData()
                    this.$emit('close-dialog')    //触发关闭对话框事件
                }
            }).catch(() => {
                this.$message({
                    message:'修改失败！',
                    type:'error'
                })
            })
          } else {
            //添加
            let data = this.formData
            addAndEditFamily(data).then((res) => {
                if(res.code == 200){
                    this.loading = false
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                    this.updateData()
                    this.$emit('close-dialog')
                }
            }).catch(() => {
              this.$message({
                message:'添加失败！',
                type:'error'
              })
            })
          }
        }
      })
    },
    updateData() {
      //触发事件，让父组件更新表格数据
      this.$emit('update-data')
    },
    resetForm() {
      this.$refs['form'].resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog{
  margin-top: 6vh !important;
}
</style>