<template>
<div>
  <div class="bg-white"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
      <div class="formBox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <el-row class="paddingleft"  style="padding-bottom:20px">
            <div class="bigTit">承接要求</div>
            <el-col :span="8">
              <el-form-item label="期望学生性别：" prop="sex">
                <el-select v-model="ruleForm.sex" placeholder="选择期望学生性别">
                  <el-option label="男生" value="男生"></el-option>
                  <el-option label="女生" value="女生"></el-option>
                  <el-option label="不限" value="不限"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item label="期望学生年级：" prop="grade">
                <el-select v-model="ruleForm.grade" multiple placeholder="选择期望学生年级">
                    <el-option
                        v-for="item in grade_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item label="接受短租：" prop="shortrent">
                <el-select v-model="ruleForm.shortrent" placeholder="选择是否接受短租">
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row class="rowwhitetopbor">
            <div class="bigTit">服务信息</div>
            <el-col :span="8">
              <el-form-item label="可接国际生数量：" prop="student">
                  <el-input v-model="ruleForm.student" placeholder="输入可接国际生数量"></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item label="饮食偏好：" prop="diet">
                <el-select v-model="ruleForm.diet" placeholder="选择饮食偏好">
                  <el-option label="纯美式" value="纯美式"></el-option>
                  <el-option label="中式" value="中式"></el-option>
                  <el-option label="韩式" value="韩式"></el-option>
                  <el-option label="欧式" value="欧式"></el-option>
                  <el-option label="混合" value="混合"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row class="paddingleft" style="padding-bottom:20px">
            <el-col :span="8">
              <el-form-item label="是否提供额外接送：" prop="delivery">
                <el-select v-model="ruleForm.delivery" placeholder="选择是否提供额外接送">
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="8">
              <el-form-item label="餐饮提供：" prop="dietprovision">
                <el-select v-model="ruleForm.dietprovision" multiple placeholder="选择餐饮提供">
                    <el-option
                        v-for="item in dietprovision_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item> 
            </el-col>
          </el-row>
         
        </el-form>
      </div>
    </div>
    <div class="btnBox">
        <el-button round type="primary" @click="submitForm('ruleForm')">保存修改</el-button>
        <el-button round @click="back()">返回</el-button>
    </div>
</div>
</template>

<script>
import { addAndEdit,getHouseDetail } from '../../../api/form'
export default {
  data() {
    return {
        loading: false,
        ruleForm: {
            sex: '', // 期望学生性别
            grade: '', // 期望学生年级
            shortrent: '', // 接受短租
            student: '', // 国际生数量
            diet: '', // 饮食偏好
            delivery: '', // 提供外送
            dietprovision: '', // 餐饮提供
        },
        rules: {
            
        },
        grade_options:[
            {value: '幼儿园',label: '幼儿园'},
            {value: '1-3年级',label: '1-3年级'},
            {value: '4-5年级',label: '4-5年级'},
            {value: '6-8年级',label: '6-8年级'},
            {value: '9-12年级',label: '9-12年级'},
            {value: '本科生',label: '本科生'},
            {value: '研究生',label: '研究生'},
        ],
        dietprovision_options:[
            {value: '早餐',label: '早餐'},
            {value: '午餐',label: '午餐'},
            {value: '晚餐',label: '晚餐'},
            {value: '无',label: '无'},
        ]
    }
  },
//生命周期 - 创建完成（访问当前this实例）
  created() {
    
  },
//生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // 回显
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    // 回显
    getDetail(id){
      this.loading = true;
      getHouseDetail({id: id}).then((res)=>{
        if(res.code == 200){
          if(res.data != null){
            if(res.data.js_grade != null){
              this.ruleForm.grade = res.data.js_grade.split(";") // 期望学生年级
            }
            if(res.data.js_catering != null){
              this.ruleForm.dietprovision = res.data.js_catering.split(";") // 餐饮提供
            }
            this.ruleForm.sex = res.data.js_gender // 期望学生性别
            this.ruleForm.shortrent = res.data.short_rental // 接受短租
            this.ruleForm.student = res.data.js_student // 国际生数量
            this.ruleForm.diet = res.data.js_diet // 饮食偏好
            this.ruleForm.delivery = res.data.js_jiesong // 提供外送
            this.loading = false;
          }
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 添加、编辑
          addAndEdit({
            id:this.$route.query.id,
            js_gender:this.ruleForm.sex,
            js_grade:this.ruleForm.grade.join(";"),
            short_rental:this.ruleForm.shortrent,
            js_student:this.ruleForm.student,
            js_diet:this.ruleForm.diet,
            js_jiesong:this.ruleForm.delivery,
            js_Catering:this.ruleForm.dietprovision.join(";"),
          }).then((res)=>{
            if(res.code == 200){
              this.$message({
                message: '保存成功，继续下一步',
                type: 'success'
              });
              // 修改步骤条状态
              this.$emit('stepactive', 3)
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 返回上一步
    back(){
        // 修改步骤条状态
        this.$emit('stepactive', 1)
    },
  },
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
// form
.bg-white{
  background: #ffffff;
}
.bigTit{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E1E1E;
    border-bottom: 1px solid #EFEFEF;
    padding: 0px 0 22px 0;
    margin-bottom: 45px;
}
.formBox{
    padding-top:35px;
}
.demo-ruleForm{
    .paddingleft{
        padding-left: 45px;
    }
    .padding45{
        padding: 45px 0 20px 45px;
    }
    .padding45-2{
        padding: 45px 0 0 45px;
    }
    .rowwhitetopbor{
        padding-left: 45px;
        border-top: 20px solid #FAFAFA;
        padding-top:45px;
    }
    .rowwhitebombor{
        padding-left: 45px;
        border-bottom: 20px solid #FAFAFA;
        padding-bottom: 20px;
    }
}
::v-deep .el-input{
  width: 240px;
}
::v-deep .el-textarea__inner{
    background: #F8F8F8;
    width: 98%;
}
.btnBox{
    text-align: center;
    padding: 50px 0 40px 0;
}
</style>