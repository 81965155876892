import request from '@/utils/axios'
// params（get请求）
// data（post请求）
// 城市
export function getCity (params) {
    return request({
      url: '/api/getCity',
      method: 'get',
      params
    })
  }
// 城市
export function getZhou (params) {
    return request({
      url: '/api/getProvince',
      method: 'get',
      params
    })
  }
// 新增/编辑房源
export function addAndEdit (data) {
  return request({
    url: '/api/addAndEdit',
    method: 'post',
    data
  })
}
// 查看回显
export function getHouseDetail (data) {
    return request({
      url: '/api/getHouseDetail',
      method: 'post',
      data
    })
}
// 添加编辑成员信息
export function addAndEditFamily (params) {
    return request({
      url: '/api/family',
      method: 'get',
      params
    })
}
// 删除成员信息
export function deleteFamily (params) {
  return request({
    url: '/api/deleteFamily',
    method: 'get',
    params
  })
}
// 上传图片
export function uploadImg (data) {
    return request({
      url: '/api/uploadImg',
      method: 'post',
      data
    })
}
// 删除图片
export function removeImg (data) {
    return request({
      url: '/api/removeImg',
      method: 'post',
      data
    })
}
