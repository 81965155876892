<template>
  <div>
    <div class="bg-white" v-loading="loading" element-loading-text="拼命加载中">
      <div class="formBox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-row class="paddingleft">
            <div class="bigTit">房屋状况</div>
            <el-col :span="8">
              <el-form-item label="房屋类型：" prop="housetype">
                <el-select
                  v-model="ruleForm.housetype"
                  placeholder="选择房屋类型"
                >
                  <el-option label="独栋别墅" value="独栋别墅"></el-option>
                  <el-option label="联排别墅" value="联排别墅"></el-option>
                  <el-option label="公寓" value="公寓"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="猫：">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.petsituation"
                  placeholder="输入宠物情况"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="狗：">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.dog"
                  placeholder="输入宠物情况"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft">
            <el-col :span="8">
              <el-form-item label="其他宠物：">
                <el-input
                  v-model="ruleForm.qita"
                  placeholder="输入其他宠物情况"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="子女情况：" prop="children">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.children"
                  placeholder="输入子女情况"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="有几个孩子同住" prop="ischildren">
                <el-input
                  v-model="ruleForm.ischildren"
                  placeholder="输入有几个孩子与你同住"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft" style="padding-bottom: 20px">
            <el-col :span="8">
              <el-form-item label="寄宿经验：" prop="jsexperience">
                <el-select
                  v-model="ruleForm.jsexperience"
                  placeholder="选择寄宿家庭经验"
                >
                  <el-option label="1名" value="1名"></el-option>
                  <el-option label="2~3名" value="2~3名"></el-option>
                  <el-option label="4~5名" value="4~5名"></el-option>
                  <el-option label="6名以上" value="6名以上"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="价格（美元/月）：" prop="money">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.money"
                  placeholder="输入房源价格"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="rowwhitetopbor">
            <div class="bigTit">房屋设施</div>
            <el-col :span="8">
              <el-form-item label="卧室数量：" prop="bedroom">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.bedroom"
                  placeholder="输入卧室数量"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="卫浴数量：" prop="bathroom">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.bathroom"
                  placeholder="输入卫浴数量"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="空闲卧室：" prop="nullbedroom">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.nullbedroom"
                  placeholder="输入空闲卧室数量"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="paddingleft">
            <el-col :span="8">
              <el-form-item label="汽车数量：" prop="car">
                <el-input
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  v-model="ruleForm.car"
                  placeholder="输入汽车数量"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="独立卫浴：" prop="duwei">
                <el-select v-model="ruleForm.duwei" placeholder="选择独立卫浴">
                  <el-option label="是" value="是"></el-option>
                  <el-option label="否" value="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="可签标签：" prop="iskeqian">
                <el-select
                  v-model="ruleForm.iskeqian"
                  placeholder="选择可签标签"
                >
                  <el-option label="可签" value="可签"></el-option>
                  <el-option label="不可签" value="不可签"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="rowwhitebombor">
            <el-col :span="8">
              <el-form-item label="包含设施：" prop="facilities">
                <el-select
                  v-model="ruleForm.facilities"
                  multiple
                  placeholder="请选择包含设施"
                >
                  <el-option
                    v-for="item in facilities_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="房屋视频链接：" prop="video">
                <el-input
                  v-model="ruleForm.video"
                  placeholder="输入房屋视频链接"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="住家签约链接：" prop="video">
                <div >
                  <div class="url"><a :href="SignUrl" target="_blank">{{ SignUrl }}</a></div>
                  <!-- <button @click="copy(SignUrl)">复制</button> -->
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="paddingleft"
            style="padding: 45px 0 20px 45px"
            v-loading="uploadloading"
            element-loading-text="拼命上传中"
          >
            <div class="bigTit">房屋图片上传</div>
            <el-col :span="24">
              <el-form-item label="封面图">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '5')
                  "
                  :file-list="fileList_5"
                  :on-change="
                    (file, fileList) =>
                      otherSectionFile(file, fileList, '封面图')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="房屋外观">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '0')
                  "
                  :file-list="fileList_0"
                  :on-change="
                    (file, fileList) =>
                      otherSectionFile(file, fileList, '房屋外观')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="寄宿家庭成员">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '1')
                  "
                  :file-list="fileList_1"
                  :on-change="
                    (file, fileList) =>
                      otherSectionFile(file, fileList, '住家照片')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="学生卧室">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '2')
                  "
                  :file-list="fileList_2"
                  :on-change="
                    (file, fileList) =>
                      otherSectionFile(file, fileList, '学生卧室')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="起居室">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '3')
                  "
                  :file-list="fileList_3"
                  :on-change="
                    (file, fileList) =>
                      otherSectionFile(file, fileList, '起居室')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="厨房">
                <el-upload
                  ref="upload"
                  action
                  :auto-upload="false"
                  :on-preview="handlePicPreview"
                  :on-remove="
                    (file, fileList) => handleRemove(file, fileList, '4')
                  "
                  :file-list="fileList_4"
                  :on-change="
                    (file, fileList) => otherSectionFile(file, fileList, '厨房')
                  "
                  list-type="picture-card"
                  accept=".jpg,.jpeg,.png,.gif"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 图片预览  -->
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
    </div>
    <div class="btnBox">
      <el-button round type="primary" @click="submitForm('ruleForm')"
        >保存修改</el-button
      >
      <el-button round @click="back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import {
  addAndEdit,
  getHouseDetail,
  uploadImg,
  removeImg,
} from "../../../api/form";
export default {
  data() {
    return {
      SignUrl: "", //住家签约链接
      loading: false,
      uploadloading: false,
      ruleForm: {
        housetype: "", // 房屋类型
        petsituation: "", // 猫
        dog: "", // 狗
        qita: "", // 其他宠物
        jsexperience: "", // 寄宿家庭经验
        children: "", // 子女情况
        ischildren: "", // 输入有几个孩子与你同住
        money: "", // 价格
        bedroom: "", // 卧室数量
        bathroom: "", // 卫浴数量
        nullbedroom: "", // 空卧室数量
        car: "", // 汽车数量
        duwei: "", // 独立卫浴
        facilities: "", // 包含设施
        iskeqian: "", // 房源状态（是否可签）
        video: "", // 视频链接
      },
      rules: {},
      facilities_options: [
        {
          value: "WIFI",
          label: "WIFI",
        },
        {
          value: "空调",
          label: "空调",
        },
        {
          value: "储物间/衣帽间",
          label: "储物间/衣帽间",
        },
        {
          value: "书桌/写字台",
          label: "书桌/写字台",
        },
        {
          value: "洗衣机",
          label: "洗衣机",
        },
        {
          value: "电视",
          label: "电视",
        },
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "冰箱",
          label: "冰箱",
        },
      ],
      fileList_0: [], // 房屋外观
      fileList_1: [],
      fileList_2: [],
      fileList_3: [],
      fileList_4: [],
      fileList_5: [], //封面图
      AllFileList: [], // 数组合并
      otherFiles: null,
      dialogVisible: false,
      dialogImageUrl: "",
      delArray: [], // 删除图片
    };
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.SignUrl = "http://sys.ixlzj.com/#/sign";
    // 回显
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    //复制
    copy(text) {
      this.$copyText(text).then((res) => {
        alert("复制成功！");
        this.$message({
          message: "复制成功",
          type: "success",
        });
      });
    },
    // 回显
    getDetail(id) {
      this.loading = true;
      getHouseDetail({ id: id }).then((res) => {
        if (res.code == 200) {
          if (res.data != null) {
            if (res.data.js_facilities != null) {
              this.ruleForm.facilities = res.data.js_facilities.split(";"); // 包含设施
            }
            this.ruleForm.housetype = res.data.js_type; // 房屋类型
            this.ruleForm.petsituation = res.data.js_cat; // 猫
            this.ruleForm.dog = res.data.js_dog; // 狗
            this.ruleForm.qita = res.data.js_otherpets; // 其他
            this.ruleForm.jsexperience = res.data.js_experience; // 寄宿家庭经验
            this.ruleForm.children = res.data.ju_hzsl; // 子女情况
            this.ruleForm.ischildren = res.data.ju_tzhz; // 是否和子女同住
            this.ruleForm.money = res.data.js_pay; // 价格
            this.ruleForm.bedroom = res.data.js_bedroom; // 卧室数量
            this.ruleForm.bathroom = res.data.js_bathroom; // 卫浴数量
            this.ruleForm.nullbedroom = res.data.js_freebedroom; // 空卧室数量
            this.ruleForm.car = res.data.js_car; // 汽车数量
            this.ruleForm.duwei = res.data.js_diwy; // 独立卫浴
            this.ruleForm.iskeqian = res.data.sign; // 房源状态（是否可签）
            this.ruleForm.video = res.data.js_video; // 视频链接
            this.loading = false;
            // 图片列表回显
            if (res.data.houseimg) {
              for (var i = 0; i < res.data.houseimg.length; i++) {
                if (res.data.houseimg[i].img_type == "房屋外观") {
                  this.fileList_0.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                } else if (res.data.houseimg[i].img_type == "住家照片") {
                  this.fileList_1.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                } else if (res.data.houseimg[i].img_type == "学生卧室") {
                  this.fileList_2.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                } else if (res.data.houseimg[i].img_type == "起居室") {
                  this.fileList_3.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                } else if (res.data.houseimg[i].img_type == "厨房") {
                  this.fileList_4.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                } else if (res.data.houseimg[i].img_type == "封面图") {
                  this.fileList_5.push({
                    img_address: res.data.houseimg[i].img_address,
                    url: res.data.houseimg[i].imgs,
                    house_id: res.data.houseimg[i].house_id,
                    img_type: res.data.houseimg[i].img_type,
                    id: res.data.houseimg[i].id,
                  });
                }
              }
              // 数组合并
              this.AllFileList = this.fileList_0
                .concat(this.fileList_1)
                .concat(this.fileList_2)
                .concat(this.fileList_3)
                .concat(this.fileList_4);
            }
          }
        }
      });
    },
    // 图片预览
    handlePicPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片删除
    handleRemove(file, fileList, deltype) {
      switch (deltype) {
        case "0":
          this.delArray = this.fileList_0;
          break;
        case "1":
          this.delArray = this.fileList_1;
          break;
        case "2":
          this.delArray = this.fileList_2;
          break;
        case "3":
          this.delArray = this.fileList_3;
          break;
        case "4":
          this.delArray = this.fileList_4;
          break;
        case "5":
          this.delArray = this.fileList_5;
          break;
        default:
          break;
      }
      this.delArray.map((item, index) => {
        if (item.uid == file.uid) {
          removeImg({
            imgname: item.img_address,
            id: item.id,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.delArray.splice(index, 1);
                this.AllFileList = this.fileList_0
                  .concat(this.fileList_1)
                  .concat(this.fileList_2)
                  .concat(this.fileList_3)
                  .concat(this.fileList_4);
              }
            })
            .catch(() => {
              this.$message({
                message: "删除失败！",
                type: "error",
              });
            });
        }
      });
    },
    // 上传图片
    otherSectionFile(file, fileList, type) {
      this.uploadloading = true;
      const typeArr = ["image/png", "image/gif", "image/jpeg", "image/jpg"];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      // if (!isJPG) {
      //     this.$message.error('只能上传图片!');
      //     return false
      // }
      // if (!isLt3M) {
      //     this.fileList_1.splice(fileList - 1, 1);
      //     this.$message.error('上传图片大小不能超过 3MB!');
      //     return false
      // }
      this.otherFiles = file.raw;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("img", this.otherFiles);
      uploadImg(formData).then((res) => {
        if (res.code == 200) {
          this.uploadloading = false;
          this.$message({
            message: "上传成功",
            type: "success",
          });

          if (type == "房屋外观") {
            this.fileList_0.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          } else if (type == "住家照片") {
            this.fileList_1.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          } else if (type == "学生卧室") {
            this.fileList_2.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          } else if (type == "起居室") {
            this.fileList_3.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          } else if (type == "厨房") {
            this.fileList_4.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          } else if (type == "封面图") {
            this.fileList_5.push({
              img_address: res.data.path,
              url: res.data.url,
              house_id: this.$route.query.id,
              img_type: type,
            });
          }
          // 数据合并
          this.AllFileList = this.fileList_0
            .concat(this.fileList_1)
            .concat(this.fileList_2)
            .concat(this.fileList_3)
            .concat(this.fileList_4)
            .concat(this.fileList_5);
        }
      });
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if(this.fileList_1 == ''){
          //     this.$message({
          //         message:'未上传家庭成员照片',
          //         type:'error'
          //     })
          //     return
          // }
          // if(this.fileList_2 == ''){
          //     this.$message({
          //         message:'未上传学生卧室照片',
          //         type:'error'
          //     })
          //     return
          // }
          // if(this.fileList_3 == ''){
          //     this.$message({
          //         message:'未上传起居室照片',
          //         type:'error'
          //     })
          //     return
          // }
          // if(this.fileList_4 == ''){
          //     this.$message({
          //         message:'未上传厨房照片',
          //         type:'error'
          //     })
          //     return
          // }
          // 添加、编辑
          addAndEdit({
            id: this.$route.query.id,
            js_type: this.ruleForm.housetype,
            js_cat: this.ruleForm.petsituation,
            js_dog: this.ruleForm.dog,
            js_otherpets: this.ruleForm.qita,
            js_experience: this.ruleForm.jsexperience,
            ju_hzsl: this.ruleForm.children,
            ju_tzhz: this.ruleForm.ischildren,
            js_Pay: this.ruleForm.money,
            js_bedroom: this.ruleForm.bedroom,
            js_bathroom: this.ruleForm.bathroom,
            js_freebedroom: this.ruleForm.nullbedroom,
            js_car: this.ruleForm.car,
            js_diwy: this.ruleForm.duwei,
            js_facilities: this.ruleForm.facilities.join(";"),
            sign: this.ruleForm.iskeqian,
            js_video: this.ruleForm.video,
            uploadImg: this.AllFileList,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "保存成功，继续下一步",
                type: "success",
              });
              // 修改步骤条状态
              this.$emit("stepactive", 2);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回上一步
    back() {
      // 修改步骤条状态
      this.$emit("stepactive", 0);
    },
  },
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
// form
.bg-white {
  background: #ffffff;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: cover;
}
.bigTit {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
  border-bottom: 1px solid #efefef;
  padding: 0px 0 22px 0;
  margin-bottom: 45px;
}
.formBox {
  padding-top: 35px;
}
.demo-ruleForm {
  .paddingleft {
    padding-left: 45px;
  }
  .padding45 {
    padding: 45px 0 20px 45px;
  }
  .padding45-2 {
    padding: 45px 0 0 45px;
  }
  .rowwhitetopbor {
    padding-left: 45px;
    border-top: 20px solid #fafafa;
    padding-top: 45px;
  }
  .rowwhitebombor {
    padding-left: 45px;
    border-bottom: 20px solid #fafafa;
    padding-bottom: 20px;
  }
}
::v-deep .el-input {
  width: 240px;
}
::v-deep .el-textarea__inner {
  background: #f8f8f8;
  width: 98%;
}
.btnBox {
  text-align: center;
  padding: 50px 0 40px 0;
}
</style>