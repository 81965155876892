<template>
  <div>
    <el-empty image="" description="已完成上传"></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
       
    }
  },
//生命周期 - 创建完成（访问当前this实例）
  created() {
  },
//生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
::v-deep .el-empty__description p{
    font-size: 16px;
    color: #6d6d6d;
    font-weight: bold;
}
</style>