<template>
<div>
    <div style="text-align:right;margin-bottom:20px">
        <el-button type="primary" round @click="handleAdd">添加成员</el-button>
    </div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      v-loading="loading" 
      element-loading-text="拼命加载中" 
      highlight-current-row border 
      style="width:100%"
    >
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="性别" prop="js_cy_sex" sortable></el-table-column>
      <el-table-column label="出生日期" prop="js_birth2"></el-table-column>
      <el-table-column label="关系" prop="js_applicant"></el-table-column>
      <el-table-column label="在美状态" prop="jtcy_zmjlzt"></el-table-column>
      <el-table-column label="族裔" prop="jtcy_zz"></el-table-column>
      <el-table-column label="学历" prop="js_education2"></el-table-column>
      <el-table-column label="职业" prop="js_professional2"></el-table-column>
      <el-table-column label="宗教信仰" prop="js_cy_beliefs"></el-table-column>
      <el-table-column label="是否在该地址居住" prop="jtcy_sfzxdzjz"></el-table-column>
      <!-- <el-table-column label="是否在该地址居住" prop="jtcy_sfzxdzjz" :formatter="formatjtcy_sfzxdzjz"></el-table-column> -->
      <el-table-column label="操作" width="170">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" @click="hendleDelete(scope.$index, scope.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 子组件弹出框 -->
    <UserForm
      v-if="dialogVisible"
      :dialog-visible="dialogVisible" 
      :is-edit-dialog="isEditDialog" 
      :initial-form-data="ruleForm"
      @close-dialog="handleCloseDialog"
      @update-data="getTableData"
    ></UserForm>
    <div class="btnBox">
        <el-button round type="primary" @click="submitForm()">已添加，下一步</el-button>
        <el-button round @click="back()">返回</el-button>
    </div>
</div>
</template>

<script>
import { getHouseDetail,deleteFamily } from '../../../api/form'
import UserForm from './UserForm.vue'
export default {
  components:{
    UserForm
  },
  data() {
    return {
        ruleForm: {
            id: '', // id
            name: '', // 名
            js_cy_sex: '', // 性别
            js_birth2: '', // 出生日期
            js_applicant: '', // 关系
            jtcy_zmjlzt: '', // 在美状态
            jtcy_zz: '', // 族裔
            js_education2: '', // 学历
            js_professional2: '', // 职业
            js_cy_beliefs: '', // 宗教
            jtcy_sfzxdzjz:'', // 是否在该地址居住
        },
        tableData: [],     //保存当前显示的数据
        allTableData: [],  //保存所有数据
        loading: false,    //是否提示加载中
        selected: [],      //保存被选中的行
        total: 0,          //总行数
        page: 1,           //当前页码
        pageSize: 10,      //每页显示行数
        disablePagination: false,    //禁用分页
        dialogVisible: false,        //是否显示新增/编辑对话框
        isEditDialog: false,        //是否为编辑对话框
        fyid:'' // 房源id  添加成员使用
    }
  },
  methods:{
    formatjtcy_sfzxdzjz: function (row, column, cellValue) {
      if (cellValue === 'true'){
        return '是';
      }else if (cellValue === 'false'){
        return '否';  
      }else{
        return '';
      }
    },
    submitForm() {
        // 修改步骤条状态
        this.$emit('stepactive', 5);
    },
    handleSelectionChange(val) {
      //选择项发生变化时触发执行
      this.selected = val;
    },
    handleEdit(index, row) {
      //编辑指定行
      this.ruleForm = {
        id: row.id, // id
        name: row.name, // 名
        js_cy_sex: row.js_cy_sex, // 性别
        js_birth2: row.js_birth2, // 出生日期
        js_applicant: row.js_applicant, // 关系
        jtcy_zmjlzt: row.jtcy_zmjlzt, // 在美状态
        jtcy_zz: row.jtcy_zz, // 族裔
        js_education2: row.js_education2, // 学历
        js_professional2: row.js_professional2, // 职业
        js_cy_beliefs: row.js_cy_beliefs, // 宗教
        jtcy_sfzxdzjz: row.jtcy_sfzxdzjz, // 是否在该地址居住
      }
      this.dialogVisible = true
      this.isEditDialog = true
    },
    handleAdd() {
      //新增
      this.ruleForm = {
        jtcy: this.fyid, // 名
        name: '', // 名
        js_cy_sex: '', // 性别
        js_birth2: '', // 出生日期
        js_applicant:'', //关系
        jtcy_zmjlzt: '', // 在美状态
        jtcy_zz: '', // 族裔
        js_education2: '', // 学历
        js_professional2: '', // 职业
        js_cy_beliefs: '', // 宗教
        jtcy_sfzxdzjz:'', // 是否在该地址居住
      },
      this.dialogVisible = true
      this.isEditDialog = false
    },
    //删除指定行
    hendleDelete(index, row) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确认',
        cancleButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //删除
        this.loading = true
        deleteFamily({
          id: row.id
        }).then((res) => {
          if(res.code == 200){
            this.loading = false
            this.$message({
              message:'删除成功',
              type:'success'
            })
            this.getTableData() //重新获取表格数据
          }
        })
      }).catch(() => {
        //取消删除
      })
    },
    getPage(page, pageSize) {
      //从allTableData数组中获取某页数据
      // if( page>0 && (page+pageSze) < this.allTableData.length ){
        return this.allTableData.slice(pageSize*(page-1), pageSize*(page))
      // }
    },
    getTableData() {
        //获取表格数据
        this.loading = true
        getHouseDetail({id:this.$route.query.id}).then(res => {
            if(res.code == 200){
                this.fyid = res.data.id //房源id 
                this.allTableData = res.data.family
                this.total = res.data.family.length
                this.tableData = this.getPage(this.page, this.pageSize)
                this.loading = false
            }
        }).catch(err => {
            console.log(err)
            this.loading = false
        })
    },
    handleCurrentChange(val) {
      //选择分页时触发
      this.page = val
      this.tableData = this.getPage(this.page, this.pageSize)
    },
    handleSizeChange(val) {
      //pageSize改变时触发
      this.pageSize = val
      this.tableData = this.getPage(this.page, this.pageSize)
    },
    handleCloseDialog() {
      //关闭对话框
      this.dialogVisible = false
    },
    // 返回上一步
    back(){
        // 修改步骤条状态
        this.$emit('stepactive', 2)
    },
  },
  mounted() {
    this.getTableData();
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
// form
.bg-white{
  background: #ffffff;
}
.bigTit{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E1E1E;
    border-bottom: 1px solid #EFEFEF;
    margin: 0 0 0 42px;
    padding:35px 0 22px 0;
}
.formBox{
    padding-top:35px;
}
.demo-ruleForm{
    .paddingleft{
        padding-left: 45px;
    }
    .padding45{
        padding: 45px 0 20px 45px;
    }
    .padding45-2{
        padding: 45px 0 0 45px;
    }
    .rowwhitetopbor{
        padding-left: 45px;
        border-top: 20px solid #FAFAFA;
        padding-top:45px;
    }
    .rowwhitebombor{
        padding-left: 45px;
        border-bottom: 20px solid #FAFAFA;
        padding-bottom: 20px;
    }
}
::v-deep .el-input{
  width: 240px;
}
::v-deep .el-textarea__inner{
    background: #F8F8F8;
    width: 98%;
}
.btnBox{
    text-align: center;
    padding: 50px 0 40px 0;
}
</style>