<template>
  <div class="main">
    <!-- 房源编号 -->
    <!-- <div class="housingInfo" v-if="step_active == 0">
      <div class="info">
        <div class="tit">房源编号：XLZJ1211</div>
        <ul class="doc">
          <li>创建时间：2019 01 02 14: 50</li>
          <li>创建用户：user</li>
          <li>来源：自有资源</li>
        </ul>
      </div>
      <el-button round style="border: 1px solid #079562; color: #079562"
        >查看动态</el-button
      >
    </div> -->
    <!-- 步骤条 -->
    <el-steps :active="step_active" align-center finish-status="success" class="stepBox">
      <el-step title="第一步" @click.native="stepBtn(0)" description="房主信息"></el-step>
      <el-step title="第二步" @click.native="stepBtn(1)" description="房屋情况"></el-step>
      <el-step title="第三步" @click.native="stepBtn(2)" description="承接要求"></el-step>
      <el-step title="第四步" @click.native="stepBtn(3)" description="家庭成员信息"></el-step>
      <el-step title="第五步" @click.native="stepBtn(4)" description="完成上传"></el-step>
    </el-steps>
    <!-- 动态渲染组件 -->
    <component :is="componentName" @stepactive='onstepactive'></component>
  </div>
</template>

<script>
import { UserInfo,Houseinfo,Undertake,FamilyUserInfo,Sussess } from './components'
export default {
  components:{
    UserInfo,
    Houseinfo,
    Undertake,
    FamilyUserInfo,
    Sussess
  },
  data() {
    return {
      step_active: 0,
      componentName: UserInfo, // 默认显示房主信息组件
    };
  },
  created() {
    
  },
  methods: {
    stepBtn(e){
      if(e != '' || e != null){
        this.step_active = e
        this.on_componentName(e)
      }
    },
    // 获取步骤条状态
    onstepactive(data){
      this.step_active = data
      this.on_componentName(data)
    },
    // 动态渲染组件
    on_componentName(step_active){
      if(step_active == 0){
        this.componentName = UserInfo
      }else if(step_active == 1){
        this.componentName = Houseinfo
      }else if(step_active == 2){
        this.componentName = Undertake
      }else if(step_active == 3){
        this.componentName = FamilyUserInfo
      }else if(step_active == 5){
        this.componentName = Sussess
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/mixin";
.main {
  background: #fafafa;
}
// 房源信息
.housingInfo {
  background: #ffffff;
  border-radius: 10px;
  border: 2px dashed #d7d7d7;
  padding: 28px 33px 30px 33px;
  @include flexbox();
  .info {
    font-size: 12px;
    .tit {
      font-weight: 600;
      color: #079562;
      margin-bottom: 15px;
    }
    .doc {
      @include flexbox();
      li {
        margin-right: 95px;
        font-weight: 400;
        color: #6d6d6d;
      }
      li:last-of-type {
        margin-right: 0;
      }
    }
  }
}
// 步骤条
.stepBox{
  margin: 60px 0 50px 0;
  cursor: pointer;
}
::v-deep .el-step.is-horizontal .el-step__line{
  background: #F2F2F2;
}
::v-deep .is-success{
  color: #079562;
  border-color: #079562;
}
// ::v-deep .el-step__head.is-process{
//   color: #079562;
//   border-color: #079562;
// }
::v-deep .el-step__title{
  font-size: 12px;
  font-weight: normal;
}
::v-deep .el-step__title.is-success{
  color: #2F2F32;
}
::v-deep .el-step__description{ 
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2F2F32;
}
::v-deep .el-step__description.is-success {
  color: #2F2F32;
}

</style>